
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({
})
export default class extends ListViewVue {
  listType = "router";

  getList() {
    return this.$get("/manager/page/list", {
      ...this.mesureData,
    });
  }

  async delPage(row) {
    await this.$postNotify("/manager/page/delete", {
      id: row.id,
    });
    this.refresh();
  }

  operatTemplate(id?) {
    if (id) {
      return this.$goto({
        name: "custom-edit-detail",
        query: {
          id,
        },
      });
    }
    this.$goto({
      name: "custom-edit-detail",
    });
  }
}
