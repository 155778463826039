
import { LockDec } from "@/decorators";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  loading = false;
  countdown = false;
  codeloading = false;

  post = {
    phone: "",
    loginpwd: "",
    code: "",
  }

  goto(path) {
    this.$goto({
      path
    });
  }

  @LockDec("codeloading")
  async getCode() {
    let { error, data, message } = await this.$post('/manager/common/get-code', {
      phone: this.post.phone,
      action: "forgot",
    });
    if (error) return this.$error(message);
    this.countdown = true;
  }

  gotoUrl(e) {
    e.preventDefault();
  }

  @LockDec("loading")
  async request() {
    await this.$postNotify('/manager/common/forgot', this.post);
    this.$goto("/login");
  }
}
