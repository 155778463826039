
import { Component, Vue } from 'vue-facing-decorator';
import Normal from './normal/index.vue';
import PayVip from './pay-vip/index.vue';
@Component({
  components: {
    Normal,
    PayVip
  },
})
export default class extends Vue {

  tablist = [
    {
      name: "普通会员",
      value: "normal",
    },
    {
      name: "付费会员",
      value: "vip",
    }
  ];

  activeName = "normal";
}

