import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5278b27b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-body-list-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderItem = _resolveComponent("OrderItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, i) => {
      return (_openBlock(), _createBlock(_component_OrderItem, {
        key: i,
        modelValue: item,
        onRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('refresh', $event)))
      }, null, 8, ["modelValue"]))
    }), 128))
  ]))
}