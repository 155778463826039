import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e475e4b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"20px 0"} }
const _hoisted_2 = { class: "flex-button-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      "label-width": "100px",
      class: "demo-ruleForm"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "简介",
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_input, {
                type: "textarea",
                onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.info = null)),
                modelValue: _ctx.ruleForm.signature,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ruleForm.signature) = $event))
              }, null, 8, ["modelValue"])
            ]),
            (_ctx.info)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass({ ['tips-' + _ctx.info.type]: true })
                }, _toDisplayString(_ctx.info.msg), 3))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitForm()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("修改")
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('reject', { msg: '取消选择' })))
            }, {
              default: _withCtx(() => [
                _createTextVNode("取消")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}