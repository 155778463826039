import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c1336d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-popover-content-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "menu-popover-content-box"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    disabled: !_ctx.modelValue.isGroup || _ctx.activePath == _ctx.modelValue.aliasName,
    width: "220",
    "popper-class": "menu-popover-box",
    placement: "right-start",
    "popper-options": {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 22]
        },
      },]
    }
  }, {
    reference: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.children, (item, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
            (item.children && item.children.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (citem, c) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "menu-popover-content-item-box",
                      onClick: ($event: any) => (_ctx.gotoItem(citem)),
                      key: c
                    }, _toDisplayString(citem.name), 9, _hoisted_3))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ]),
    _: 3
  }, 8, ["disabled"]))
}