
import { dialog } from '@/plugins/dialog/index'
import InstanceBox from './index.vue';

/**
 * 选内容
 * @param {*} options 
 * @returns 
 */
export default function (options) {
  options = Object.assign({
    multiple: true
  }, options)
  return dialog({
    title: '请选内容',
    content() {
      return <InstanceBox options={options}></InstanceBox>
    }
  })
}