
import { ElCheckbox, ElInput } from "element-plus";
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, State } from 'vuex-facing-decorator';
import { ListViewVue } from '@/common/BaseVue'
import CreateShop from './com/create-shop.vue'
import ChangePwd from './com/change-pwd.vue'
import BindWechat from './com/bind-wechat.vue'

@Component({})
export default class extends ListViewVue {
  listType = "router";

  @State("user", {
    namespace: "user"
  })
  userInfo;

  @Action('user/logout')
  userLogout;

  async getList() {
    return this.$get("/manager/store/list", { ...this.mesureData });
  }

  async createShop() {
    await this.$dialog({
      props: {
        width: 600
      },
      content: () => {
        return <CreateShop />
      }
    })
    this.$success('创建成功')
    this.refresh()
  }

  @Action("user/getUserInfo")
  getUserInfo;

  created() {
    this.getUserInfo()

  }
  async bindWechat() {
    await this.$dialog({
      content: () => {
        return <BindWechat />
      }
    })
    this.getUserInfo()
  }

  gotoManager() {
    location.href = '/manager'
  }

  handleCommand(command) {
    this[command] && this[command]();
  }
  async logout() {
    await this.userLogout()
  }

  async changePwd() {
    await this.$dialog({
      props: {
        width: '600px'
      },
      content: () => {
        return <ChangePwd />
      }
    })
    await this.$sleep(500);
    await this.userLogout({
      needRquest: false,
      tooltip: false,
    })
  }
  async gotoIndex(storeId) {
    let { error, data, message } = await this.$post("/manager/store/choose", {
      id: storeId,
    });
    if (error && error.code != 20001) return this.$error(message);
    this.$goto({
      path: "/",
    });
  }
}
