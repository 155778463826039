import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d32cc662"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tips-box"
}
const _hoisted_2 = {
  key: 1,
  class: "tips-box"
}
const _hoisted_3 = {
  key: 2,
  class: "tips-box fail-status-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_form_item = _resolveComponent("ui-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ui_form = _resolveComponent("ui-form")!

  return (!_ctx.force && _ctx.source)
    ? (_openBlock(), _createBlock(_component_el_form, {
        key: 1,
        "label-width": "130px",
        class: "demo-ruleFindorm"
      }, {
        default: _withCtx(() => [
          (_ctx.source.status == 1 || _ctx.source.status == 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 申请中请稍等 "))
            : (_ctx.source.status == 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, "小程序创建成功"))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, " 创建失败:" + _toDisplayString(_ctx.source.failMessage), 1)),
          (_ctx.source.status != 3)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createVNode(_component_el_form_item, { label: "公司名称" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.source.name), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "企业代码" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.source.code), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "企业代码类型" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.codeName), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "法人微信" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.source.legalPersonaWechat), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "法人名称" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.source.legalPersonaName), 1)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.source.status == 0)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 4,
                style: {"margin-top":"40px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.force = true))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("重新申请")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_ui_form, {
        key: 2,
        ref: "formCheck",
        "label-width": "130px",
        class: "demo-ruleFindorm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ui_form_item, {
            label: "公司名称",
            data: _ctx.ruleForm.name,
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.ruleForm.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ruleForm.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["data"]),
          _createVNode(_component_ui_form_item, {
            label: "企业代码",
            data: _ctx.ruleForm.code,
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.ruleForm.code,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ruleForm.code) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["data"]),
          _createVNode(_component_ui_form_item, {
            label: "企业代码类型",
            data: _ctx.ruleForm.codeType,
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.ruleForm.codeType,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ruleForm.codeType) = $event)),
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      label: item.name,
                      value: item.value,
                      key: i
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["data"]),
          _createVNode(_component_ui_form_item, {
            label: "法人微信",
            data: _ctx.ruleForm.legalPersonaWechat,
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.ruleForm.legalPersonaWechat,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ruleForm.legalPersonaWechat) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["data"]),
          _createVNode(_component_ui_form_item, {
            label: "法人名称",
            data: _ctx.ruleForm.legalPersonaName,
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.ruleForm.legalPersonaName,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.ruleForm.legalPersonaName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["data"]),
          _createVNode(_component_ui_form_item, {
            label: "",
            style: {"margin-top":"40px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submitForm()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("保存")
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('reject', { msg: '取消选择' })))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("取消")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}