
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import PriceInput from "./price-input.vue";
interface Props {
  info: any
  goods: any
}
@Component({
  components: { PriceInput },
})
export default class extends TSX<Props>()(Vue) implements Props {
  @Prop()
  info: any

  @Prop()
  goods: any

  get skuMap() {
    let res = {};
    if (!this.goods?.spec) return res;
    this.goods.skuList.map((item, i) => {
      let currentItem = this.$getSpecList(this.goods.spec, i);
      res[item.id] = {
        ...item,
        name: currentItem ? currentItem.map((item) => item.name).join("、") : this.goods.name
      }
    })

    return res;
  }

  list = [];

  created() {
    this.list = JSON.parse(JSON.stringify(this.info.skuList))
  }

  confirmData() {
    this.$emit('resolve', this.list);
  }

  closeDialog() {
    this.$emit('reject', { msg: "已取消" });
  }

  getPrice(price: string, row) {
    if (!row.value) return price
    let res = (() => {
      switch (`${row.type}`) {
        case "1":
          return price.toDecimal().mul(row.value).div(10).toString();
        case "2":
          return price.toDecimal().minus(row.value).toString();
        case "3":
          return row.value;
      }
      return price;
    })();
    return parseFloat(res).toFixed(2);
  }
}
