import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4691f817"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo-img-box" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { style: {"color":"#999"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { style: {"color":"red"} }
const _hoisted_6 = { style: {"color":"#999"} }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { style: {"color":"red"} }
const _hoisted_9 = { style: {"color":"#999"} }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { style: {"color":"red"} }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "APPID" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.dataInfo.appid), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "小程序LOGO" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.dataInfo.head_image_info)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.dataInfo.head_image_info.head_image_url,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setImage && _ctx.setImage(...args)))
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(" 本年 剩余修改次数"),
          (_ctx.dataInfo.head_image_info)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createElementVNode("b", _hoisted_5, _toDisplayString(_ctx.dataInfo.head_image_info.modify_quota -
          _ctx.dataInfo.head_image_info.modify_used_count), 1),
                _createTextVNode("次")
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "小程序名称" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_link, {
          type: "primary",
          onClick: _ctx.changeNickName
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.dataInfo.nickname || "未设置"), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("div", _hoisted_6, [
          _createTextVNode(" 本年 剩余修改次数"),
          (_ctx.dataInfo.nickname_info)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _createElementVNode("b", _hoisted_8, _toDisplayString(_ctx.dataInfo.nickname_info.modify_quota -
          _ctx.dataInfo.nickname_info.modify_used_count), 1),
                _createTextVNode("次")
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "简介" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_link, {
          type: "primary",
          onClick: _ctx.changeSignature
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.dataInfo.signature_info
          ? _ctx.dataInfo.signature_info.signature || "未设置"
          : "未设置"), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("div", _hoisted_9, [
          _createTextVNode(" 本月 剩余修改次数"),
          (_ctx.dataInfo.signature_info)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                _createElementVNode("b", _hoisted_11, _toDisplayString(_ctx.dataInfo.signature_info.modify_quota -
          _ctx.dataInfo.signature_info.modify_used_count), 1),
                _createTextVNode("次")
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "小程序码" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_link, {
          type: "primary",
          onClick: _ctx.viewCode
        }, {
          default: _withCtx(() => [
            _createTextVNode("查看")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "已选分类" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table, {
          data: _ctx.categoryInfo.categories,
          style: {"width":"100%"},
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              label: "分类1",
              width: "180"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(row.first_name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "分类2",
              width: "180"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(row.second_name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, { label: "操作" }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_el_popconfirm, {
                  title: "是否删除该分类",
                  onConfirm: ($event: any) => (_ctx.deleteRow(row)),
                  style: {"margin-left":"10px"}
                }, {
                  reference: _withCtx(() => [
                    _createVNode(_component_ui_button, { type: "danger" }, {
                      default: _withCtx(() => [
                        _createTextVNode("删除")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onConfirm"]),
                (row.audit_status == 3)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, " 审核通过"))
                  : (row.audit_status == 2)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, "审核不通过"))
                    : (row.audit_status == 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, " 审核中"))
                      : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"]),
        _createVNode(_component_el_link, {
          type: "primary",
          onClick: _ctx.addCategory
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 添加")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}