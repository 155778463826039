import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router';
import NotFound from '@/entrys/404/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '管理后台登录'
    },
    component: () => import("@/entrys/login/index.vue")
  },
  {
    path: '/register',
    name: 'register',
    component: () => import("@/entrys/register/index.vue"),
    meta: {
      title: '管理后台注册'
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import("@/entrys/forgot/index.vue"),
    meta: {
      title: '管理后台找回密码'
    },
  },
  {
    path: "/home",
    component: () => import("@/entrys/home/index.vue"),
    meta: {
      title: '选择店铺',
      auth: true
    },
  }
]

const router = createRouter({
  history: process.env.VUE_APP_NODE_ENV == 'production' ? createWebHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
  routes
})

let ignores = ['/login', '/register', '/forgot']

let isFirst = true;

let loopEvent = (list) => {
  return list.map((item) => {
    if (item.children) {
      let redirect;
      if (item.redirect) {
        if (item.children[0].aliasName) {
          redirect = {
            name: item.children[0].aliasName
          };
        } else if (item.children[0]?.children) {
          redirect = {
            name: item.children[0]?.children[0]?.aliasName
          };
        }
      }
      let groupArrayList = []
      if (item.isGroup) {
        loopEvent(item.children).map((citem) => {
          if (citem.children) {
            groupArrayList.push(...citem.children)
          }
        });
      }
      return {
        redirect,
        meta: { ...item.meta, isMenu: item.show },
        name: item.aliasName,
        component: () => import(`@/views/${item.filePath}`),
        path: item.path,
        children: item.isGroup ? groupArrayList : loopEvent(item.children)
      };
    }
    return {
      meta: { ...item.meta, isMenu: item.show },
      name: item.aliasName,
      component: () => import(`@/views/${item.filePath}`),
      path: item.path,
    };
  })
};

router.beforeEach(async (to, from, next) => {
  let store = (await import('@/store')).default;
  const token = store?.state['user']?.token;
  if (ignores.includes(to.path)) {
    return next()
  }
  if (!token) {
    return next({
      path: '/login'
    });
  }

  if (to.matched.length === 0 && isFirst) {
    let { error, data } = await store.dispatch('store/getLastMenu');
    if (error) {
      return next("/home")
    }
    isFirst = false;
    let list = loopEvent(data.list);
    router.addRoute({
      name: "index",
      path: "/",
      component: () => import("@/entrys/index/index.vue"),
      redirect: { name: list[0].name },
      children: list,
      meta: {
        auth: true
      },
    });
    router.addRoute({
      path: "/:pathMatch(.*)",
      component: NotFound
    });
    return next(to);
  }
  return next();
});

router.afterEach(async (to, from) => {
  let title = `${to.meta?.title || ''}`;
  let store = (await import('@/store')).default;
  await store.dispatch("store/getLastStore")
  if (store.state['store']?.store?.name) {
    document.title = title ? [title, store.state['store']?.store?.name].join("-") : store.state['store']?.store?.name;
    return;
  }
  document.title = title ? [title, '知之网络'].join("-") : '知之网络';
})
export default router;