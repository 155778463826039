import { App } from "vue";
import mitt from 'mitt'
import router from '@/router'
import { alert } from '@/plugins/alert/index'
import { ElNotification, ElMessage } from 'element-plus'
import sendGoods from './register/send-goods'
import inputContent from "./register/input-content";
import returnGoods from "./register/return-goods";
import modifyPrice from "./register/modify-price";
import chooseMiniLink from './register/choose-mini-link/index'
import chooseGoods from './register/choose-goods/index'
import chooseVipGroup from "./register/choose-vip-group";
import choosePage from "./register/choose-page";
import chooseCategory from "./register/choose-category";
import chooseAddress from "./register/choose-address";
import chooseMedia from "./register/choose-media";
import chooseCase from "./register/choose-case";
import chooseCaseCategory from "./register/choose-case-category";

const utils = {
  $event: mitt(),
  $error(str: string) {
    return ElMessage.error(str)
  },
  $success(str: string) {
    return ElMessage.success(str)
  },
  $sleep(t = 1000) {
    return new Promise((r) => {
      setTimeout(() => {
        r('')
      }, t)
    })
  },
  $replace(data: Parameters<typeof router.replace>[0]) {
    return router.replace(data);
  },
  $goto(data: Parameters<typeof router.push>[0], target?: string) {
    if (target) {
      let { href } = router.resolve(data);
      window.open(href, target);
      return
    }
    return router.push(data);
  },
  async $chooseFile(payload = { count: 1 }): Promise<File[]> {
    let input = document.createElement("input")
    payload = { count: 1, ...payload }
    input.type = 'file';
    if (payload.count > 1) {
      input.multiple = true;
    }
    input.click()
    return new Promise((r, re) => {
      input.addEventListener('cancel', () => {
        re()
      });
      input.addEventListener('change', () => {
        return r(Array.from(input.files).slice(0, payload.count))
      });
    })
  },
  async $fileToBase64(file: File): Promise<string> {
    return new Promise((r) => {
      const reader = new FileReader();
      reader.onload = (evt) => {
        r(`${evt.target.result}`);
      };
      reader.readAsDataURL(file);
    })
  },
  $download(url: string) {
    let a = document.createElement("A");
    a.setAttribute("href", url);
    a.setAttribute("target", '_blank');
    document.documentElement.appendChild(a);
    a.click();
    document.documentElement.removeChild(a);
  },

  $getUUID() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  },
  /**
   *  选图片
   */
  $chooseImage(data?) { return chooseMedia({ title: '图片选择', ...data, type: '1' }) },
  /**
   *  选视频
   */
  $chooseVideo(data?) { return chooseMedia({ title: '视频选择', ...data, type: '2' }) },
  /**
   *  图标+图片
   */
  $chooseIcon(data?) { return chooseMedia({ title: '图标选择', ...data, type: '3' }) },
  /**
   *  图视频都可以选
   */
  $chooseMedia(data?) { return chooseMedia({ title: '媒体选择', ...data, type: '' }) },
  /**
   * 选择地址
   */
  $chooseAddress: chooseAddress,
  /**
   * 填写小程序
   */
  $chooseMiniLink: chooseMiniLink,

  $chooseCategory: chooseCategory,
  $chooseGoods: chooseGoods,
  $choosePage: choosePage,
  /**
   * 选择VIP 
   */
  $chooseVipGroup: chooseVipGroup,
  /** 发货 */
  $sendGoods: sendGoods,
  $inputContent: inputContent,
  $returnGoods: returnGoods,
  $modifyPrice: modifyPrice,

  $chooseCase: chooseCase,
  $chooseCaseCategory: chooseCaseCategory,

  $getSpecList(specs, index: number) {
    if (!specs) return []
    function cartesianProduct(...arrays) {
      return arrays.reduce((accumulator, currentArray) => {
        const result = [];
        accumulator.forEach((accumulatorValue) => {
          currentArray.forEach((currentValue) => {
            result.push([...accumulatorValue, currentValue]);
          });
        });
        return result;
      }, [[]]);
    }
    const selectedSpecs = specs.map(spec => spec.specList);
    const combinations = cartesianProduct(...selectedSpecs);
    const selectedCombination = combinations[index];
    if (!selectedCombination) {
      return null;
    }
    return selectedCombination
  },
}

declare module '@vue/runtime-core' {
  type UtilsApi = typeof utils;
  export interface ComponentCustomProperties extends UtilsApi {
  }
}

export default {
  install(app: App) {
    for (let key in utils) {
      app.config.globalProperties[key] = utils[key];
    }
  }
}

export { utils }