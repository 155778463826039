import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-feccf08a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_page = _resolveComponent("ui-page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_input, {
        style: {"width":"200px"},
        placeholder: "请输入微信号",
        modelValue: _ctx.wechat,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.wechat) = $event))
      }, null, 8, ["modelValue"]),
      _withDirectives((_openBlock(), _createBlock(_component_ui_button, { onClick: _ctx.addMember }, {
        default: _withCtx(() => [
          _createTextVNode("添加")
        ]),
        _: 1
      }, 8, ["onClick"])), [
        [_directive_loading, _ctx.addLoading]
      ])
    ]),
    _withDirectives((_openBlock(), _createBlock(_component_ui_table, {
      data: _ctx.source.list
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "体验成员",
          prop: "wechat"
        }),
        _createVNode(_component_el_table_column, {
          label: "操作",
          width: "130",
          align: "right"
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_ui_button, {
              onClick: ($event: any) => (_ctx.unbind(row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("移除")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])), [
      [_directive_loading, _ctx.source.loading]
    ]),
    _createVNode(_component_ui_page, {
      modelValue: _ctx.page.page,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page.page) = $event)),
      info: _ctx.pageInfo
    }, null, 8, ["modelValue", "info"])
  ]))
}