import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d297125"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { style: {"width":"300px","height":"300px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_VueCropper = _resolveComponent("VueCropper")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-width": "100px",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "" }, {
        default: _withCtx(() => [
          (!_ctx.option.img)
            ? (_openBlock(), _createBlock(_component_el_link, {
                key: 0,
                type: "primary",
                class: "link-style-box"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("选择图片 "),
                  _createElementVNode("input", {
                    type: "file",
                    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeFile && _ctx.changeFile(...args)))
                  }, null, 32)
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_link, {
                  type: "primary",
                  class: "link-style-box",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.option.img = ''))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 清除 "),
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Close)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_VueCropper, {
                    original: true,
                    fixed: true,
                    autoCrop: true,
                    outputType: "png",
                    canMove: true,
                    centerBox: true,
                    enlarge: "2",
                    ref: "cropper",
                    img: _ctx.option.img,
                    limitMinSize: [100, 100],
                    mode: "cover"
                  }, null, 8, ["img"])
                ])
              ]))
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitForm()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("修改")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('reject', { msg: '取消选择' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}