import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ff8030c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "choose-goods-box" }
const _hoisted_2 = { class: "name-style-box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "choose-goods-operat-box" }
const _hoisted_6 = { style: {"margin":"0 10px 0 0"} }
const _hoisted_7 = { style: {"color":"red"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ChooseSku = _resolveComponent("ChooseSku")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_page = _resolveComponent("ui-page")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_table, {
      data: _ctx.source.list,
      style: {"margin-top":"10px"},
      "row-key": "id"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, { width: "280" }, {
          header: _withCtx(() => [
            _createVNode(_component_el_checkbox, {
              class: "name-check-box",
              modelValue: _ctx.isCheckAll,
              onChange: _ctx.toggleCheckAll
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 商品名称 ")
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          default: _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_checkbox, {
                modelValue: _ctx.filterChecked(row, _ctx.checked),
                indeterminate: _ctx.filterIndeter(row, _ctx.checked),
                onChange: ($event: any) => (_ctx.toggleCheck($event, row)),
                class: "name-check-box"
              }, null, 8, ["modelValue", "indeterminate", "onChange"]),
              _createElementVNode("img", {
                src: row.cover
              }, null, 8, _hoisted_3),
              _createElementVNode("div", null, [
                _createElementVNode("div", null, _toDisplayString(row.name), 1),
                _createElementVNode("div", null, _toDisplayString(row.showPrice), 1)
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "规格",
          align: "right"
        }, {
          default: _withCtx(({ row }) => [
            (row.skuList && row.skuList.length)
              ? (_openBlock(), _createBlock(_component_ChooseSku, {
                  key: 0,
                  skuValue: row.skuList,
                  spec: row.spec,
                  checked: _ctx.checked[row.id],
                  onChange: ($event: any) => (_ctx.changeSku(row.id, $event))
                }, null, 8, ["skuValue", "spec", "checked", "onChange"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "stock",
          label: "库存",
          align: "right"
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_ui_page, {
      modelValue: _ctx.page.page,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page.page) = $event)),
      info: _ctx.pageInfo
    }, null, 8, ["modelValue", "info"]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(" 已选：商品("),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.goodsLength), 1),
        _createTextVNode(") ")
      ]),
      _createVNode(_component_el_button, {
        onClick: _ctx.confirmDialog,
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("确定")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, { onClick: _ctx.closeDialog }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}