import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-529bead4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "set-sku-price-box" }
const _hoisted_2 = { class: "name-style-box" }
const _hoisted_3 = {
  key: 0,
  style: {"margin-left":"5px"}
}
const _hoisted_4 = { class: "operat-button-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_PriceInput = _resolveComponent("PriceInput")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, "商品名称：" + _toDisplayString(_ctx.goods.name), 1),
    _createVNode(_component_ui_table, {
      data: _ctx.list,
      style: {"margin-top":"10px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "title",
          "min-width": "180"
        }, {
          header: _withCtx(() => [
            _createTextVNode(" 规格名称 ")
          ]),
          default: _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.skuMap[row.skuId])
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.skuMap[row.skuId].name), 1),
                    _createElementVNode("div", null, "库存" + _toDisplayString(_ctx.skuMap[row.skuId].stock), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "价格(元)",
          width: "150"
        }, {
          default: _withCtx(({ row }) => [
            (_ctx.skuMap[row.skuId])
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.skuMap[row.skuId].price), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "打折",
          width: "140"
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_PriceInput, {
              onChange: ($event: any) => {
            row.value = $event;
          row.type = 1;
          },
              modelValue: row.value,
              showType: "1",
              type: row.type,
              style: {"width":"110px"}
            }, {
              append: _withCtx(() => [
                _createTextVNode("折")
              ]),
              _: 2
            }, 1032, ["onChange", "modelValue", "type"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "减价",
          width: "140"
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_PriceInput, {
              onChange: ($event: any) => {
            row.value = $event;
          row.type = 2;
          },
              modelValue: row.value,
              showType: "2",
              type: row.type,
              style: {"width":"110px"}
            }, {
              append: _withCtx(() => [
                _createTextVNode("元")
              ]),
              _: 2
            }, 1032, ["onChange", "modelValue", "type"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "指定价格",
          width: "140"
        }, {
          default: _withCtx(({ row }) => [
            _createVNode(_component_PriceInput, {
              onChange: ($event: any) => {
            row.value = $event;
          row.type = 3;
          },
              modelValue: row.value,
              showType: "3",
              type: row.type,
              style: {"width":"110px"}
            }, {
              append: _withCtx(() => [
                _createTextVNode("元")
              ]),
              _: 2
            }, 1032, ["onChange", "modelValue", "type"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "最终价",
          width: "150"
        }, {
          default: _withCtx(({ row }) => [
            (_ctx.skuMap[row.skuId])
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.getPrice(_ctx.skuMap[row.skuId].price, row)), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          onClick: _ctx.confirmData,
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("确定")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, { onClick: _ctx.closeDialog }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}