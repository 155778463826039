import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25a6280c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-input-item-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      style: {"width":"200px"},
      modelValue: _ctx.modelValue.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.name) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_el_select, {
      style: {"margin":"0 10px"},
      modelValue: _ctx.modelValue.type,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.type) = $event)),
      placeholder: "请选择"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.option, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_input, {
      placeholder: "输入框提示",
      style: {"width":"200px"},
      modelValue: _ctx.modelValue.placeholder,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.placeholder) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_el_checkbox, {
      modelValue: _ctx.modelValue.isRequired,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.isRequired) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode("必选")
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("span", {
      class: "del-btn",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('delete')))
    }, "删除")
  ]))
}