
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any;

  @Prop()
  activePath: string;
  gotoItem(item) {
    this.$goto({ name: item.aliasName })
  }
}
