import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9967f7ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!

  return (_ctx.source)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_link, {
          type: "primary",
          onClick: _ctx.chooseData
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.source.name), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_link, {
          type: "danger",
          icon: "Close",
          style: {"margin":"0 0 0 10px"},
          onClick: _ctx.clearData
        }, null, 8, ["onClick"])
      ]))
    : (_openBlock(), _createBlock(_component_el_link, {
        key: 1,
        type: "primary",
        onClick: _ctx.chooseData
      }, {
        default: _withCtx(() => [
          _createTextVNode("选择会员活动")
        ]),
        _: 1
      }, 8, ["onClick"]))
}