
import { Component } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'

@Component({})
export default class extends ListViewVue {
  listType = "router";

  getList() {
    return this.$get("/manager/postage/list", this.mesureData);
  }


  async delRow(id) {
    let { error, message } = await this.$post("/manager/postage/delete", {
      id,
    });
    if (error) return this.$error(message);
    this.$success("删除成功");
    this.refresh();
  }

  operat(id?) {
    if (id) {
      return this.$goto({
        name: "postage-operat",
        query: {
          id,
        },
      });
    }
    this.$goto({
      name: "postage-operat",
    });
  }
}
