
import { Component, Vue } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({
  components: {}
})
export default class extends ListViewVue {
  listType = "router";
  async getList() {
    return this.$get("/manager/case/list", {
      ...this.mesureData,
    });
  }
  async deleteRow(id) {
    await this.$postError("/manager/case/delete", { id });
    this.$success("删除成功");
    this.refresh();
  }
  operatGoods(id?) {
    if (id) {
      return this.$goto({
        name: "content-operat",
        query: {
          id,
        },
      });
    }
    this.$goto({
      name: "content-operat",
    });
  }
}
