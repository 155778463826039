
import { ValidateDec } from '@/decorators';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

@Component({
})
export default class extends Vue {
  @Prop()
  options: any;
  ruleForm = {
    appid: "",
    page: "",
  }
  @ValidateDec("formTable")
  async submitForm() {
    this.$emit("resolve", this.ruleForm);
  }
}
