
import { Component, Vue } from 'vue-facing-decorator'
import { State } from 'vuex-facing-decorator';
import StorePage from "./page/store.vue";
import MiniPage from "./page/mini.vue";
import IcpPage from "./page/icp.vue";
import PayPage from "./page/pay.vue";
import AuthPage from './page/auth.vue';

@Component({
  components: {
    StorePage,
    MiniPage,
    IcpPage,
    PayPage,
    AuthPage
  }
})
export default class extends Vue {
  @State((state) => state.store.store)
  store: any;

  tabIndex = 0;
}
