import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1327c6fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"20px"} }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { class: "goods-input-style-box" }
const _hoisted_4 = { class: "goods-image-box" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  style: {"color":"#868686"},
  class: "goods-input-style-box"
}
const _hoisted_7 = {
  key: 1,
  style: {"width":"100%"}
}
const _hoisted_8 = {
  key: 0,
  class: "tips-text-box"
}
const _hoisted_9 = {
  key: 1,
  class: "tips-text-box"
}
const _hoisted_10 = {
  key: 2,
  class: "tips-text-box"
}
const _hoisted_11 = { class: "footer-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_form_item = _resolveComponent("ui-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_form = _resolveComponent("ui-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ui_form, {
      "label-width": "150px",
      style: {"width":"90%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ui_form_item, { label: "选择商品" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.modelValue.name) + "(" + _toDisplayString(_ctx.modelValue.showPrice) + ")", 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: _ctx.modelValue.cover
                }, null, 8, _hoisted_5)
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, { label: "佣金模式" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio, {
              modelValue: _ctx.postData.type,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.postData.type) = $event)),
              label: 1
            }, {
              default: _withCtx(() => [
                _createTextVNode("不参与分销")
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_el_radio, {
              modelValue: _ctx.postData.type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.postData.type) = $event)),
              label: 0
            }, {
              default: _withCtx(() => [
                _createTextVNode("默认分配")
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_el_radio, {
              modelValue: _ctx.postData.type,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.postData.type) = $event)),
              label: 2
            }, {
              default: _withCtx(() => [
                _createTextVNode("自定义比例")
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_el_radio, {
              modelValue: _ctx.postData.type,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.postData.type) = $event)),
              label: 3
            }, {
              default: _withCtx(() => [
                _createTextVNode("自定义金额")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, { label: "佣金设置" }, {
          default: _withCtx(() => [
            (_ctx.postData.type == 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, "不参与分销的商品无法设置佣金"))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_ui_table, {
                    border: "",
                    style: {"width":"330px"},
                    data: _ctx.isDefaultSale ? _ctx.modelValue.levelList : _ctx.postData.levelList
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        label: "等级",
                        width: "180"
                      }, {
                        default: _withCtx(({ row }) => [
                          _createTextVNode(_toDisplayString(row.name), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, { label: "佣金比例" }, {
                        default: _withCtx(({ row }) => [
                          _createVNode(_component_el_input, {
                            modelValue: row.value,
                            "onUpdate:modelValue": ($event: any) => ((row.value) = $event),
                            disabled: _ctx.postData.type == 0
                          }, {
                            append: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.postData.type == 3 ? '元' : '%'), 1)
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"]),
                  (_ctx.postData.type == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, "i：按分销员等级佣金比例计算。"))
                    : (_ctx.postData.type == 2)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, "i：按当前商品设置的佣金比例计算。"))
                      : (_ctx.postData.type == 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, "i：按当前商品设置的佣金金额计算，请仔细确认自定义佣金金额，以避免佣金大于客户实付金额。"))
                        : _createCommentVNode("", true)
                ]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_el_button, {
        onClick: _ctx.submit,
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("保存")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, { onClick: _ctx.cancel }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}