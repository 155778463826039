import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af943e8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"20px"} }
const _hoisted_2 = { class: "operat-box" }
const _hoisted_3 = { class: "store-form-botton-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_switch = _resolveComponent("ui-switch")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      "label-width": "100px",
      class: "store-form-box"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table, {
          data: _ctx.list,
          style: {"width":"100%"},
          border: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              label: "填报字段",
              width: "150"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_el_input, {
                  modelValue: row.name,
                  "onUpdate:modelValue": ($event: any) => ((row.name) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "占位名",
              width: "150"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_el_input, {
                  modelValue: row.placeholder,
                  "onUpdate:modelValue": ($event: any) => ((row.placeholder) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "字段属性",
              width: "90"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(_ctx.getFormatType(row.type)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "原名",
              width: "90"
            }, {
              default: _withCtx(({ row }) => [
                _createTextVNode(_toDisplayString(row.mainInfo ? row.mainInfo.name : '--'), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "必填",
              width: "75"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_ui_switch, {
                  trueValue: 1,
                  falseValue: 0,
                  modelValue: row.isRequire,
                  "onUpdate:modelValue": ($event: any) => (_ctx.changeRequire(row, $event))
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "启用",
              width: "75"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_ui_switch, {
                  trueValue: 1,
                  falseValue: 0,
                  modelValue: row.isEnabled,
                  "onUpdate:modelValue": ($event: any) => (_ctx.changeEnabled(row, $event))
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "操作",
              align: "right"
            }, {
              default: _withCtx(({ row, $index }) => [
                _createElementVNode("div", _hoisted_2, [
                  (!row.mainId)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_el_link, {
                          onClick: ($event: any) => (_ctx.editItem($index))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("编辑")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_el_link, {
                          onClick: ($event: any) => (_ctx.removeItem($index))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("删除")
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ], 64))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_link, {
                    type: "primary",
                    disabled: $index == 0,
                    onClick: ($event: any) => (_ctx.upIndex($index))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("上移")
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"]),
                  _createVNode(_component_el_link, {
                    type: "primary",
                    disabled: $index == _ctx.list.length - 1,
                    onClick: ($event: any) => (_ctx.downLindex($index))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("下移")
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives((_openBlock(), _createBlock(_component_el_button, {
        onClick: _ctx.submitForm,
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("确定")
        ]),
        _: 1
      }, 8, ["onClick"])), [
        [_directive_loading, _ctx.submitLoading]
      ]),
      _createVNode(_component_el_button, { onClick: _ctx.cancel }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}