
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  skuValue: any

  @Prop()
  spec: any

  @Prop()
  checked: any

  skuDetail = null

  chooseList = {}

  @Watch("checked", {
    deep: true,
    immediate: true
  })
  checkedChange() {
    let res = {}
    Object.keys(this.checked || {}).map((item) => {
      res[item] = true;
    })
    this.chooseList = res
  }


  created() {
    this.skuDetail = this.skuValue
  }


  get chooseText() {
    let length = Object.keys(this.checked || {}).length;
    if (length) {
      return `已选择规格(${length})`;
    }
    return "请选择规格";
  }

  get isIndeter() {
    return this.chooseLength < this.value.length && this.chooseLength > 0;
  }

  get isCheckAll() {
    return this.chooseLength == this.value.length;
  }
  get chooseLength() {
    return Object.keys(this.chooseList).length;
  }

  get value() {
    if (!this.skuDetail) return []
    return this.skuDetail.map((item, index) => {
      item.columnArray = this.$getSpecList(this.spec, index)
      return item
    });
  }

  get computSku() {
    return this.spec
  }

  toggleAll(value) {
    if (value) {
      let data = {};
      for (let i = 0; i < this.value.length; i++) {
        let item = this.value[i];
        data[item.id] = true;
      }
      this.chooseList = data;
      return;
    }
    this.chooseList = {};
  }

  changeItem(checked, item) {
    if (checked) {
      this.chooseList[item.id] = true;
    } else {
      delete this.chooseList[item.id];
    }
    this.chooseList = { ...this.chooseList };
  }

  confirmData() {
    this.$emit("change", Object.keys(this.chooseList));
    this.closeDialog();
  }

  closeDialog() {
    (this.$refs.popover as any).hide();
  }

  async showEvent() {
    this.checkedChange()
  }

}
