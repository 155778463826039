
import { LockDec } from '@/decorators';
import { Action, State } from 'vuex-facing-decorator';
import { Component, Vue, Prop } from 'vue-facing-decorator'
@Component({})
export default class extends Vue {
  @Prop()
  title: string;

  @State("user", {
    namespace: "user"
  })
  userInfo: any;

  async handleCommand(command) {
    switch (command) {
      case "backhome":
        let { href } = this.$router.resolve("/home")
        return location.href = href
      case "logout":
        return this.userLogout()
    }
  }


  @Action('user/logout')
  userLogout;
}
