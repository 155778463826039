
import { Action, State } from 'vuex-facing-decorator';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import MenuContainer from './com/menu-container/index.vue'
@Component({
  components: {
    MenuContainer
  }
})
export default class extends Vue {
  @State("store", {
    namespace: 'store'
  })
  store: any;

  @State("menu", {
    namespace: 'store'
  })
  menu: any;

  @State("user", {
    namespace: "user"
  })
  userInfo: any;


  @Action("store/getStore")
  getStore;

  @Action("user/getUserInfo")
  getUserInfo;

  gotoCitem(item) {
    this.$goto({ name: item.aliasName })
  }

  get subActiveKey() {
    for (let i = this.$route.matched.length; i--; i >= 0) {
      if (this.$route.matched[i].meta.isMenu) {
        return String(this.$route.matched[i + 1].name);
      }
    }
  }

  get subMenuList() {
    return this.subMenuMap[this.activePath]
  }

  get subMenuMap() {
    let resMap: Record<string, any[]> = {}
    let loopEvent = (list) => {
      if (!list) return;
      list.map((item) => {
        if (item.isGroup) {
          resMap[item.aliasName] = item.children
        } else {
          loopEvent(item.children)
        }
      })
    }
    loopEvent(this.menu.list)
    return resMap
  }

  get activePath(): string {
    for (let i = this.$route.matched.length; i--; i >= 0) {
      if (this.$route.matched[i].meta.isMenu) {
        return String(this.$route.matched[i].name);
      }
    }
    return '';
  }

  async created() {
    this.getStore();
    this.getUserInfo();
  }


}
