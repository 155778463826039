import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed350e2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  style: {"color":"#096FFE"}
}
const _hoisted_2 = {
  key: 2,
  style: {"color":"#868686"}
}
const _hoisted_3 = { class: "footer-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_form_item = _resolveComponent("ui-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_form = _resolveComponent("ui-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ui_form, {
      "label-width": "150px",
      style: {"width":"90%"},
      ref: "form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ui_form_item, {
          label: "申请时间",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$format(_ctx.modelValue.createTime)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "申请用户",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modelValue?.salesmanInfo?.name || '-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "电话",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modelValue?.salesmanInfo.phone), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "提现金额",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modelValue.amount), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "提现方式",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 银行卡 ")
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "提现人",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modelValue.salesmanInfo.realName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "开户行",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modelValue.bankName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "开户支行",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modelValue.bankBranchName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "银行卡",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modelValue.cardNo), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ui_form_item, {
          label: "处理状态",
          style: {"margin-bottom":"8px"}
        }, {
          default: _withCtx(() => [
            (_ctx.modelValue.status == 0)
              ? (_openBlock(), _createBlock(_component_el_radio_group, {
                  key: 0,
                  modelValue: _ctx.postData.status,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.postData.status) = $event)),
                  class: "list-radio-group"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio, { label: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode("已完成")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_radio, { label: 2 }, {
                      default: _withCtx(() => [
                        _createTextVNode("已关闭")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : (_ctx.modelValue.status == 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, "已完成"))
                : (_ctx.modelValue.status == 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, "已关闭"))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.modelValue.status == 2)
          ? (_openBlock(), _createBlock(_component_ui_form_item, {
              key: 0,
              label: "关闭原因",
              style: {"margin-bottom":"8px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modelValue.content), 1)
              ]),
              _: 1
            }))
          : (_ctx.modelValue.status == 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.postData.status == 2)
                  ? (_openBlock(), _createBlock(_component_ui_form_item, {
                      key: 0,
                      label: "关闭原因",
                      required: "true",
                      data: _ctx.postData.content,
                      error: "请输入关闭原因",
                      style: {"margin-bottom":"8px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.postData.content,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.postData.content) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["data"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
        (_ctx.modelValue.status == 1)
          ? (_openBlock(), _createBlock(_component_ui_form_item, {
              key: 2,
              label: "流水号",
              style: {"margin-bottom":"8px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modelValue.serialNumber), 1)
              ]),
              _: 1
            }))
          : (_ctx.modelValue.status == 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (_ctx.postData.status == 1)
                  ? (_openBlock(), _createBlock(_component_ui_form_item, {
                      key: 0,
                      label: "流水号",
                      required: "true",
                      error: "请输入流水号",
                      data: _ctx.postData.serialNumber,
                      style: {"margin-bottom":"8px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.postData.serialNumber,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.postData.serialNumber) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["data"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
        (_ctx.modelValue.operatTime)
          ? (_openBlock(), _createBlock(_component_ui_form_item, {
              key: 4,
              label: "处理时间",
              style: {"margin-bottom":"8px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$format(_ctx.modelValue.operatTime)), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.modelValue.operatInfo)
          ? (_openBlock(), _createBlock(_component_ui_form_item, {
              key: 5,
              label: "处理人",
              style: {"margin-bottom":"8px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modelValue.operatInfo.name), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.modelValue.status == 0)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            onClick: _ctx.submit,
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode("保存")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_button, { onClick: _ctx.cancel }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}