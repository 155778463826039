
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
@Component({
  components: {},
})
export default class extends Vue {
  @Prop()
  modelValue: any;
  @Prop()
  list: any;
  @Prop()
  index: any;

  @Watch('modelValue.type', {
    immediate: true
  })
  modelValueTypeWatch() {
    if (this.modelValue.type == 1) {
      this.modelValue.value = parseInt(this.modelValue.value);
    }
  }
  created() {
    if ((typeof this.modelValue.type) == 'string') {
      this.modelValue.type = parseInt(this.modelValue.type)
    }
  }
  async chooseArea() {
    let disabledList = [];
    for (let i = 0; i < this.list.length; i++) {
      if (i == this.index) continue;
      disabledList.push(...this.list[i].area);
    }
    let data = await this.$chooseAddress({
      title: "包邮配送区域",
      selectList: this.modelValue.area,
      disabledList,
    });
    this.modelValue.area = data;
  }
}
