
import caseItem from "@/com/ui/custom/com/case/index";
import { Component, Vue } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  get id() {
    return this.$route.query.id;
  }
  source = { name: "" }
  fixedTemplateData: any[] = []
  templateData: any[] = []
  loading = true

  async created() {
    if (this.id) {
      let { error, data } = await this.getData();
      if (error) return;
      this.source = data;
      this.fixedTemplateData = data.fixedTemplateData || [];
      this.templateData = data.templateData || [];
    } else {
      this.templateData = [
        { ...caseItem.data.data(), delete: false }
      ];
    }
    this.loading = false;
  }
  async getData() {
    return this.$get("/manager/case/category-detail", {
      id: this.id,
    });
  }

  async operat(post) {
    let { error, message } = await this.$post("/manager/case/category-operat", {
      ...this.source,
      fixedTemplateData: post.fixed,
      templateData: post.list,
      id: this.id,
    });
    if (error) return this.$error(message);
    this.$success(this.id ? "编辑成功" : "添加成功");
    this.$goto({ name: "content-category-list" });
  }
}
