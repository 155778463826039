

import { Component, Prop, Vue } from 'vue-facing-decorator'

import { ListViewVue } from '@/common/BaseVue'
@Component({
  components: {}
})
export default class extends ListViewVue {
  listType = "router";
  gotoDetail(id?) {
    if (id) {
      return this.$goto(
        {
          name: 'staff-operat',
          query: { id }
        }
      );
    }
    this.$goto({
      name: 'staff-operat',
    });
  }
  async deleteRow(id) {
    let { error, message } = await this.$post("/manager/staff/delete", {
      id,
    });
    if (error) return this.$error(message);
    this.$success("删除成功");
    this.refresh();
  }
  getList() {
    return this.$get("/manager/staff/list", {
      ...this.mesureData,
    });
  }
}
