
import OperatIndex from "./operat/index.vue";
import OperatContent from "./operat/content.vue";
import { Component, Vue } from 'vue-facing-decorator'

@Component({
  components: {
    OperatIndex,
    OperatContent
  }
})
export default class extends Vue {
  step = 0
  formData: any = null
  loading = false;
  submitLoading = false;

  getDefaultData() {
    return {
      name: "",
      description: "",
      categoryId: null,
      cover: "",
      content: [],
      isShare: false,
      shareTitle: "",
      shareImage: "",
    };
  }


  changeEvent(ev) {
    this.step = ev
  }

  created() {
    this.formData = this.getDefaultData()
    if (this.$route.query.id) {
      this.getData();
    }
  }

  async getData() {
    this.loading = true;
    let { error, data } = await this.$get("/manager/case/detail", {
      id: this.$route.query.id,
    });
    setTimeout(() => {
      this.loading = false;
    });
    if (error) return;
    this.formData = data;
  }
  async submit() {
    this.submitLoading = true;
    let { error, data, message } = await this.$post(
      "/manager/case/operat",
      this.formData
    );
    this.submitLoading = false;
    if (error) return this.$error(message);
    if (this.formData.id) {
      this.$goto({ name: "content-list" });
      return this.$success("更新成功");
    }
    this.$success("保存成功");
    this.formData = this.getDefaultData();
  }
}

