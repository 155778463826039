
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
@Component({})
export default class extends Vue {
  @Prop()
  modelValue: any

  option = [
    {
      value: "text",
      label: "文本格式",
    },
    {
      value: "number",
      label: "数字格式",
    },
    {
      value: "idcard",
      label: "身份证格式",
    },
    {
      value: "digit",
      label: "带小数点的数字",
    },
    {
      value: "safe-password",
      label: "密码类型",
    },
  ]
}


