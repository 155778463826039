
import { TSX, Component, Prop, Vue, Watch } from 'vue-facing-decorator'
interface Props {
  options: any
}
@Component({
})
export default class extends TSX<Props>()(Vue) implements Props {

  @Prop()
  options: any
  loading = false;
  orderInfo: any = {};
  ruleForm = {
    goodsId: [],
    type: 1,
    amount: "",
    replyMessage: "",
  }

  rules = {}

  get canReturnAmount() {
    let payAmount = this.orderInfo.payAmount || "0";
    let res = (this.orderInfo.returnList || []).reduce((prev, item) => {
      return prev.sub(item.amount);
    }, payAmount.toDecimal());
    return res.toNumber();
  }

  get showAmount() {
    let num = parseFloat(this.ruleForm.amount);
    if (isNaN(num)) return 0;
    return num;
  }

  async created() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  }
  async getData() {
    let { error, data } = await this.$get("/manager/order/view", {
      id: this.options.id,
    });
    if (error) return;
    this.orderInfo = data;
  }

  async submitForm() {
    let { error, message } = await this.$post("/manager/order/create-returns", {
      ...this.ruleForm,
      id: this.options.id,
    });
    if (error) return this.$error(message);
    this.$emit('resolve', []);
  }
}
