import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f102a02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"line-height":"1.5"} }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { style: {"line-height":"1.4"} }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-width": "100px",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "服务类目" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_cascader, {
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            options: _ctx.showList,
            props: { expandTrigger: 'hover', label: 'name', value: 'id' }
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      }),
      (_ctx.showData)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_el_form_item, { label: "适用范围" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.showData.biz_scope || _ctx.showData.scope
                  }, null, 8, _hoisted_2)
                ])
              ]),
              _: 1
            }),
            (_ctx.qualify.exter_list.length > 0)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: "选择资质"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.qualify.exter_list.length) + "选1", 1),
                    _createVNode(_component_el_radio_group, {
                      modelValue: _ctx.radio,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.radio) = $event)),
                      class: "break-radio-box"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.qualify.exter_list, (item, i) => {
                          return (_openBlock(), _createBlock(_component_el_radio, {
                            key: i,
                            label: i
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.inner_list
              .map((item) => {
                return item.name;
              })
              .join("")), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certicates, (item, i) => {
              return (_openBlock(), _createBlock(_component_el_form_item, {
                label: "资质文件",
                key: i
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, _toDisplayString(item.key), 1),
                    _createElementVNode("div", null, [
                      _createTextVNode(" 上传原件或复印件，复印件务必加盖公司公章，文件格式为jpg、jpeg、bmp、gif或png，文件大小不超过10M，可拼图上传。 "),
                      (_ctx.exterList[i].url)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: _ctx.exterList[i].url,
                            target: "_blank"
                          }, "示例", 8, _hoisted_4))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_link, {
                        type: "primary",
                        class: "link-style-box"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("选择图片 "),
                          _createElementVNode("input", {
                            type: "file",
                            onChange: ($event: any) => (_ctx.changeFile($event, i))
                          }, null, 40, _hoisted_5)
                        ]),
                        _: 2
                      }, 1024),
                      (item.value)
                        ? (_openBlock(), _createBlock(_component_el_link, {
                            key: 0,
                            style: {"margin-left":"10px"},
                            type: "primary",
                            onClick: ($event: any) => (item.value = '')
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("删除 ")
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode("修改")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('reject', { msg: '取消选择' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}