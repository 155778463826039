
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue'
@Component({
  components: {}
})
export default class extends ListViewVue {
  listType = "router";
  getList() {
    return this.$get("/manager/coupon/list", this.mesureData);
  }
  operat(id?) {
    if (id) {
      return this.$goto({
        name: "coupon-operat",
        query: {
          id,
        },
      });
    }
    return this.$goto({
      name: "coupon-operat",
    });
  }
}
